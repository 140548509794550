@charset "UTF-8";
@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

.addressBox2 {
    font-family: 'Pretendard-Regular', serif;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: #11111188;
    left: 0;
    top: 0;

    & button {
        background: transparent;
    }

    .addressOutBox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .addressBg {
        text-align: left;
        background: #fff;
        display: inline-block;
        width: 450px;
        border-radius: 15px;
    }

    .addressHeader {
        position: relative;
        display: flex;
        align-items: center;
        height: 60px;
        line-height: 60px;
        margin: 0 20px;
        & button {
            padding: 10px;
            display: inline-block;
            margin: 0;
        }
        & h2 {
            display: inline-block;
            color: #000920;
            margin: 0;
            padding: 0;
            font-size: 20px;
            font-weight: bold;
        }
    }

    .addressBody {
        border-top: 1px solid #dadada;
        height: 450px;
    }
    .addressBody > div {
        height: calc(100% - 20px) !important;
    }
}
