@charset "UTF-8";
@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

body {
    clear: both;
    padding: 0;
    margin: 0;
    font-family: 'Pretendard-Regular', '맑은고딕', 'Malgun Gothic', '돋움', Dotum, '굴림', Gulim, Helvetica, sans-serif;
    color: #333333;
    font-size: 21px;
    line-height: 1.7em;
    word-spacing: 0.1em;
    background: #fff;
    width: 100%;
    height: 100%;
    min-height: 100% !important;
}

h1, h2, h3, h4 {
    padding: 0;
    margin: 0;
}

ul, ul li, dl, dl dt, dl dd {
    list-style: none;
    margin: 0;
    padding: 0;
}

textarea {
    resize: none;
}

hr {
    width: 100%;
    height: 0;
    border: 0 none;
    clear: both;
    margin: 0;
    padding: 0;
}

a {
    color: #333333;
    text-decoration: none;
}

button {
    font-family: 'Pretendard-Regular', '맑은고딕', 'Malgun Gothic', '돋움', Dotum, '굴림', Gulim, Helvetica, sans-serif;
    border: none;
    cursor: pointer;
}

.App {
    text-align: center;
}

.Logo {
    width: 198px;
    height: 50px;
}

.section {
    min-width: 1300px;
}

.section1 {
    color: white;
    text-align: center;
    height: 840px;
    position: relative;
}

.section1 .logobox {
    width: 100%;
    padding: 40px 0 0 20px;
    text-align: left;
}

.section1 .menu_box {
    height: 840px;
}

.section1 .siteTitle {
    padding: 50px 0 0 20px;
    text-align: left;
}

.section1 .siteTitle h2 {
    font-size: 45px;
    line-height: 1.2em;
}

.section1 .siteTitle .siteInfo {
    padding-top: 20px;
    display: inline-block;
    line-height: 1.5em;
}

.section1 .bottomBox {
    position: absolute;
    width: 1160px;
    bottom: 20px;
    margin: 0 20px;
    background: #11111188;
    border-radius: 30px;
    padding: 20px 0;
    text-align: left;
}

.section1 .bottomBox .bottomLeft {
    float: left;
    margin-left: 30px;
}

.section1 .bottomBox .bottomRight {
    float: right;
    margin-right: 30px;
}

.section1 .bottomBox .bottomRight h2 {
    margin-top: 15px;
    font-size: 21px;
    float: left;
    width: 130px;
}

.section1 .bottomBox .bottomRight .bottominBox {
    float: right;
    text-align: center;
}

.section1 .bottomBox .bottomRight .bottominBox h3 {
    font-size: 21px;
    font-weight: normal;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.section1 .bottomBox .bottomRight .bottominBox button {
    background: #fff;
    height: 50px;
    text-align: center;
    vertical-align: middle;
    padding: 0 20px;
    border-radius: 25px;
    margin: 0 0 0 10px;
}

.section1 .bottomBox .bottomRight .bottominBox button img {
    float: left;
    width: 34px;
    height: 34px;
}

.section1 .bottomBox .bottomRight .bottominBox button span {
    float: left;
    width: 100px;
    text-align: center;
    font-size: 19px;
    margin: 5px 0 0 5px;
}

.section1 .go_box {
    width: 100%;
    position: fixed;
    top: 25px;
    right: 20px;
    z-index: 5;
}

.section1 .go_box button {
    display: inline-block;
    width: 190px;
    height: 80px;
    border-radius: 10px;
    margin-left: 10px;
}

.section1 .go_box button:nth-child(2) {
    display: none;
}

.section1 .go_box button.roundbox-black {
    background: #111;
}

.section1 .go_box button.roundbox-blue {
    background: #255DF9;
}

.section1 .go_box button.roundbox-navi {
    background: #2A4C9E;
}

.section1 .go_box button img {
    float: left;
    margin-left: 10px;
}

.section1 .go_box button span {
    display: inline-block;
    color: #fff;
    font-size: 18px;
    text-align: left;
}

.section2 {
    padding: 80px 0;
}

.section2 h2 {
    font-size: 36px;
    line-height: 1.2em;
}

.section2 .modelBox {
    text-align: left;
    padding-top: 60px;
}

.section2 h3 {
    font-size: 30px;
    color: #333;
}

.section2 .section2_info {
    font-size: 21px;
    color: #666;
}

.section2 .modelBox ul {
    margin-top: 50px;
}

.section2 .modelBox ul li {
    float: left;
    width: 31%;
    margin: 0 20px 20px 0;
    height: 200px;
    position: relative;
}

.section2 .modelBox ul li img {
    position: absolute;
    right: 10px;
    bottom: 0;
}

.section2 .modelBox ul li .modelTitle {
    display: block;
    height: 160px;
    padding: 20px 0 0 30px;
    background: #F5F9FF;
    border-radius: 24px;
    font-size: 24px;
    font-weight: bold;
    color: #666;
}

.section2-1  {
    margin-top: 50px;
    background: #fafafa;
    clear: both;
}

.section2-1 .quickBox {
    padding-top: 60px;
    text-align: left;
    height: 750px;
}

.section3 {
    background: #EEE;
    padding: 60px 0;
}

.section3 h2 {
    font-size: 36px;
    line-height: 1.2em;
}

.section3 .section3_info {
    font-size: 21px;
    color: #666;
}

.section3 ul {
    margin-top: 30px;
    height: 250px;
}

.section3 ul li {
    width: 33%;
    float: left;
}

.section3 ul li span {
    display: block;
}

strong {
    color: #2a4c9e;
}

.section4 {
    padding: 60px 0;
}

.section4 h2 {
    font-size: 36px;
    line-height: 1.2em;
}

.section4 ul {
    margin-top: 60px;
}

.section4 ul li {
    padding-left: 50%;
    text-align: left;
    margin-right: 50px;
    margin-bottom: 60px;
}

.section4 ul li h3 {
    color: #333;
    font-size: 26px;
    line-height: 1.2em;
}

.section4 ul li h3 .topTitle {
    display: block;
    font-size: 16px;
    font-weight: normal;
    color: #6991FF;
    letter-spacing: 0.2em;
}

.section4 ul li .listInfo {
    display: block;
    margin-top: 20px;
    line-height: 1.2em;
    font-size: 20px;
    color: #666;
}

.section4 ul li .listBottom {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    border: 1px solid #255DF9;
    background: #EFF3F9;
    border-radius: 8px;
    color: #255DF9;
    font-weight: bold;
    line-height: 1.2em;
}

.section5 {
    height: 890px;
    margin-top: 80px;
}

.section5 .w1200 {
    position: relative;
    height: 800px;
}

.section5 .w1200 .list_box {
    padding: 0 40px 0 20px;
}

.section5 h2 {
    margin-bottom: 50px;
}

.section5 .live_now_button {
    width: 260px;
    float: right;
}

.section5 .live_now_button button {
    width: 260px;
    height: 260px;
    background: #255DF9;
    border: 1px solid #0032BC;
    text-align: center;
    border-radius: 18px 18px 24px 24px;
    margin-right: 20px;
}

.section5 .live_now_button button span {
    margin-top: 20px;
    display: block;
    width: 100%;
    color: #fff;
    font-weight: bold;
    font-size: 26px;
}

.section5 .live_now_list {
    width: 860px;
    float: left;
}

.section5 .live_now_list .live_now_item {
    float: left;
    width: 410px;
    margin: 0 15px 15px 0;
    text-align: left;
    position: relative;
    background: #5883D4;
    border-radius: 12px;
    height: 140px;
    font-size: 17px;
}

.section5 .live_now_list .live_now_item .live_now_address {
    padding-left: 40px;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
}

.section5 .live_now_list .live_now_item .live_now_inbox {
    position: absolute;
    bottom: 0;
    background: #F5F9FF;
    border-radius: 10px 0 10px 10px;
    width: 100%;
    height: 100px;
}

.section5 .live_now_list .live_now_item .live_now_inbox .live_now_title {
    padding: 10px 0 0 15px;
    line-height: 30px;
}

.section5 .live_now_list .live_now_item .live_now_inbox .live_now_title img {
    float: left;
    margin-right: 10px;
}

.section5 .live_now_list .live_now_item .live_now_inbox .live_now_title .live_now_icons {
    display: inline-block;
    width: 80px;
    line-height: 30px;
    float: left;
}

.section5 .live_now_list .live_now_item .live_now_inbox .live_now_title img.driver {
    margin-top: 4px;
}

.section5 .live_now_list .live_now_item .live_now_inbox .live_now_title .live_now_intitle {
    float: left;
    width: 120px;
    color: #000;
    font-weight: bold;
    margin: 0;
    padding-bottom: 5px;
}

.section5 .live_now_list .live_now_item .live_now_inbox .live_now_title .pipe {
    float: left;
    margin: 0 10px;
    color: #D9D9D9;
}

.section5 .live_now_list .live_now_item .live_now_inbox .live_now_title .live_now_device {
    float: left;
    color: #000;
    font-weight: bold;
    margin: 0;
    padding-bottom: 5px;
}

.section5 .live_now_list .live_now_item .live_now_inbox .live_now_date {
    margin-left: 15px;
    clear: both;
    color: #255DF9;
}

.section5 .live_now_list .live_now_item .live_now_inbox .live_now_date img {
    margin: 2px 10px 0 0;
}

.section5 .live_now_list .live_now_item .live_now_inbox .live_now_date span {
    display: inline-block;
    float: left;
    line-height: 22px;
    font-size: 14px;
}

.section5 .live_now_list .live_now_item .live_now_inbox .live_now_contents {
    margin-left: 15px;
    color: #666;
    clear: both;
    line-height: 22px;
    font-size: 14px;
}

.section5 .bottomBox {
    position: absolute;
    left: 0;
    bottom: -15px;
    width: 860px;
    height: 220px;
    padding: 0 10px;
}

.section5 .bottomBox .bottomButton {
    margin-top: 80px;
    height: 140px;
}

.section5 .bottomBox .bottomButton .bottomLeft {
    padding: 30px 0 0 40px;
    float: left;
    text-align: left;
    color: #fff;
    line-height: 1.3em;
}

.section5 .bottomBox .bottomButton .bottomRight {
    float: right;
}

.section5 .bottomBox .bottomRight .bottominBox {
    margin: 20px 30px 0 0;
    float: right;
    text-align: center;
    color: #fff;
}

.section5 .bottomBox .bottomRight .bottominBox h3 {
    font-size: 21px;
    font-weight: normal;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.section5 .bottomBox .bottomRight .bottominBox button {
    background: #fff;
    height: 50px;
    text-align: center;
    vertical-align: middle;
    padding: 0 20px;
    border-radius: 25px;
    margin: 0 0 0 10px;
}

.section5 .bottomBox .bottomRight .bottominBox button img {
    float: left;
    width: 34px;
    height: 34px;
}

.section5 .bottomBox .bottomRight .bottominBox button span {
    float: left;
    width: 100px;
    text-align: center;
    font-size: 19px;
    margin: 5px 0 0 5px;
}

footer {
    background: #424756;
    padding: 30px 0;
    color: #fff;
    height: 100px;
}

footer .w1200 {
    position: relative;
    text-align: left;
    line-height: 1.2em;
}

footer span {
    font-size: 16px;
    float: left;
    line-height: 16px;
    padding: 0;
    margin: 0;
    height: 16px;
}

footer span:nth-child(2), footer span:nth-child(5), footer span:nth-child(6) {
    margin-left: 10px;
    padding-left: 10px;
    border-left: 2px solid #fff;
}

footer .copy {
    display: block;
    width: 100%;
    margin-top: 20px;
}

footer .quickLink {
    position: absolute;
    right: 20px;
    top: 0px;
}

footer .quickLink button {
    background: transparent;
    margin: 0;
    padding: 0;
    margin-right: 10px;
}

header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 130px;
    z-index: 3;
    background: #fff;
    box-shadow: 0 0 3px 3px #999;
}

.downloadBox {
    position: fixed;
    left: 0;
    top: 200px;
    width: 100%;
}

.downloadBox .w1200 {
    position: relative;
}

.downloadBox .w1200 .downloadButtons {
    position: absolute;
    right: -70px;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 100px;
    background: #fff;
}

.downloadBox .w1200 .downloadButtons h2 {
    background: #255DF9;
    border-radius: 10px 10px 0 0;
    color: #fff;
    font-size: 12px;
    font-weight: normal;
    padding: 20px;
    line-height: 1.2em;
}

.downloadBox .w1200 .downloadButtons h2 span {
    display: none;
    width: 100%;
}

.downloadBox .w1200 .downloadButtons button {
    width: 100%;
    background: transparent;
    margin: 15px 0;
}

.downloadBox .w1200 .downloadButtons button:nth-child(2) {
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
}

.downloadBox .w1200 .downloadButtons button:nth-child(4),
.downloadBox .w1200 .downloadButtons button:nth-child(5) {
    display: none;
}

.downloadBox .w1200 .downloadButtons button span {
    display: block;
    width: 100%;
    font-size: 16px;
    margin-top: 10px;
}

header .w1200 {
    text-align: left;
    padding: 40px 0 0 20px;
}

.w1200 {
    margin: 0 auto;
    width: 1200px;
    text-align: center;
    position: relative;
}

.section1 .go_box .w1200 {
    text-align: right;
}

.callSMS {
    position: fixed;
    margin-top: 150px;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99;
}

.callSMS .showBox {
    margin: 0 auto;
    width: 300px;
    border-radius: 10px;
    background: #fff;
    border: 1px solid #dfdfdf;
}

.callSMS .showBox h2 {
    display: block;
    background: #255DF9;
    padding: 10px 0;
    font-size: 20px;
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.callSMS .showBox .inbox {
    padding: 15px;
}

.callSMS .showBox .inbox label {
    font-size: 16px;
    font-weight: bold;
    display: block;
    margin: 10px 0;
}

.callSMS .showBox .inbox input {
    border-radius: 10px;
    border: 1px solid #dfdfdf;
    height: 50px;
    width: 230px;
    line-height: 50px;
    font-size: 16px;
    padding: 0 10px;
}

.callSMS .showBox .buttonBox {
    padding-bottom: 15px;
}

.callSMS .showBox .buttonBox button {
    background: #255DF9;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    height: 40px;
    border-radius: 10px;
}

.callSMS .showBox .buttonBox button:nth-child(1) {
    width: 55%;
}

.callSMS .showBox .buttonBox button:nth-child(2) {
    background: #666666;
    width: 30%;
    margin-left: 10px;
}

.blogBox {
    float: right;
    width: 700px;
    margin: 20px 20px 0 0;
}

.blogBox button {
    position: relative;
    text-align: center;
    cursor: pointer;
    margin: 15px 0 0 15px;
    display: inline-block;
    padding: 0;
    width: calc(32% - 15px);
    height: 180px;
    overflow: hidden;
    background: transparent;
}

.blogBox button img {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 140px;
    border-radius: 10px;
}

.blogBox button span {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    font-size: 15px;
    color: #666;
    text-align: left;
    margin: 145px 5px 0 5px;
}

.goTelBox {
    display: none;
}

@media (max-width: 512px) {
    body {
        font-size: 16px;
    }

    header {
        display: none !important;
    }

    header .w1200 {
        padding: 10px 0 0 10px;
    }

    img.Logo {
        width: 130px;
        height: 35px;
    }

    .w1200 {
        padding: 0;
        width: auto !important;
    }

    .section {
        min-width: auto;
        width: 100% !important;
    }

    .section h2 {
        font-size: 24px !important;
    }

    .section h3 {
        font-size: 21px !important;
    }

    .section1 h2 {
        font-size: 30px !important;
        font-weight: bold !important;
    }

    .section1 .menu_box .logobox {
        padding: 20px 0 0 20px !important;
    }

    .section1 .menu_box .siteTitle {
        font-weight: bold !important;
        padding: 40px 0 0 20px !important;
    }

    .section1 .menu_box .siteInfo {
        font-size: 16px;
    }

    .section1 .bottomBox {
        left: 0;
        bottom: 0;
        margin: 0;
        border-radius: 0;
        width: 100%;
        overflow: hidden;
    }

    .section1 .bottomBox div {
        float: none;
    }

    .section1 .menu_box {
        height: 600px;
    }

    .section1 .bottomBox .bottomLeft {
        float: none;
        width: 300px;
        margin: 0;
        padding-left: 20px;
        font-weight: bold;
    }

    .section1 .bottomBox .bottomRight {
        float: none;
        margin: 20px 0 0 20px;
        text-align: center;
    }

    .section1 .bottomBox .bottomRight h2 {
        font-size: 18px !important;
        width: 200px;
        float: none;
        text-align: left;
    }

    .section1 .bottomBox .bottomRight .bottominBox {
        position: relative;
        float: none;
        text-align: left;
    }

    .section1 .bottomBox .bottomRight .bottominBox h3 {
        font-size: 16px !important;
        font-weight: bold;
        width: auto;
        text-align: left;
    }

    .section1 .bottomBox .bottomRight .bottominBox button {
        position: absolute;
        bottom: 0;
    }

    .section1 .bottomBox .bottomRight .bottominBox button:nth-child(2) {
        right: 88px;
    }

    .section1 .bottomBox .bottomRight .bottominBox button:nth-child(3) {
        right: 10px;
    }

    .section1 .bottomBox .bottomRight .bottominBox button img {
        float: unset;
    }


    .section1 .bottomBox .bottomRight .bottominBox button span {
        /*display: none;*/
        width: 100%;
        display: inline-block;
        font-size: 12px;
        margin: 0;
        padding: 0;
        color: #666;
        line-height: 1.2em;
        font-weight: bold;
    }

    .section1 .bottomBox .bottomRight .bottominBox button {
        text-align: center;
        padding: 0 5px;
        width: calc(80px - 10px);
        height: 60px;
        border-radius: 5px;
        padding-left: 8px;
        float: right;
    }

    .section1 {
        background-position: 60% 100% !important;
        background-size: 1400px auto !important;
        padding: 0 !important;
        height: 600px !important;
    }

    .section1 .go_box {
        padding: 20px 0 0 0;
        top: 0;
        left: 0;
        position: relative !important;
        text-align: center;
    }

    .section1 .go_box .w1200 {
        display: inline-block;
        text-align: center;
        padding: 0;
        margin: 0;
    }

    .section1 .go_box .w1200 button {
        margin: 0;
        width: 80%;
        margin-bottom: 10px;
    }

    .section1 .goTelBox {
        position: absolute;
        display: inline-block;
        right: 10px;
        top: 15px;
    }

    .section1 .goTelBox button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        border-radius: 5px;
        margin: 0;
        padding: 0 10px;
        background: transparent;
    }

    .section1 .goTelBox button img {
        width: 25px;
        height: 25px;
        margin-right: 5px;
    }

    .section1 .goTelBox button span {
        display: inline-block;
        color: #fff;
        font-weight: bold;
    }

    .section2 {
        margin-top: 80px;
        padding-top: 0;
    }

    .section2 .w1200 h2 {
        margin: auto;
        width: 300px;
        font-size: 25px;
    }

    .section2 h3,
    .section2-1 h3 {
        font-size: 21px !important;
        margin-bottom: 10px;
    }

    .section2 .section2_info,
    .section2-1 .section2_info{
        display: block;
        font-size: 16px;
        line-height: 1.2em;
    }

    .section2 .modelBox {
        padding: 30px 15px 0 15px;
    }

    .section2 .modelBox ul li {
        margin: 0 0 10px 0;
        width: 45%;
        height: 120px;
        padding-bottom: 10px;
    }

    .section2 .modelBox ul li:nth-child(2),
    .section2 .modelBox ul li:nth-child(4),
    .section2 .modelBox ul li:nth-child(6) {
        margin-left: 8%;
    }

    .section2 .modelBox ul li .modelTitle {
        padding: 10px 0 0 15px;
        font-size: 16px;
        height: 115px;
    }

    .section2 .modelBox ul li:nth-child(1) img {
        width: 115px;
        height: 66px;
    }

    .section2 .modelBox ul li:nth-child(2) img {
        width: 90px;
        height: 95px;
    }

    .section2 .modelBox ul li:nth-child(3) img {
        width: 115px;
        height: 80px;
    }

    .section2 .modelBox ul li:nth-child(4) img {
        width: 125px;
        height: 60px;
    }

    .section2 .modelBox ul li:nth-child(5) img {
        width: 120px;
        height: 80px;
    }

    .section2 .modelBox ul li:nth-child(6) img {
        width: 120px;
        height: 80px;
    }

    .section2-1 .quickBox {
        height: 1300px;
        padding: 30px 15px 0 15px;
        background-size: 280px auto;
        background-position: center 60px !important;
    }

    .section3 {
        padding: 30px 0;
        height: 830px;
    }

    .section3 .w1200 {
        padding: 0 15px;
    }

    .section3 .w1200 .section3_info {
        margin-top: 15px;
        display: inline-block;
        width: 250px;
        font-size: 16px;
        line-height: 1.2em;
    }

    .section3 .w1200 ul li {
        float: none;
        width: auto;
        margin-bottom: 20px;
    }

    .section3 .w1200 ul li span {
        display: block;
        line-height: 1.5em;
    }

    .section4 .w1200 {
        padding: 0 15px;
    }

    .section4 ul li {
        padding: 0;
        margin-right: 0;
        background-size: 300px auto;
        background-position: center 100px !important;
    }

    .section4 ul li span {
        display: block;
    }

    .section4 ul li .inTitle {
        font-size: 21px;
    }

    .section4 ul li .listInfo {
        display: block;
        font-size: 16px;
    }

    .section4 ul li .listBottom {
        margin-left: 10px;
        font-size: 16px;
    }

    .section4 ul li .listInfo {
        padding: 0 15px;
        display: block;
        font-size: 16px;
    }

    .section4 ul li:nth-child(1) .listInfo {
        margin-top: 200px;
    }

    .section4 ul li:nth-child(2) .listInfo {
        margin-top: 200px;
    }

    .section4 ul li:nth-child(3) .listInfo {
        margin-top: 220px;
    }

    .section4 ul li:nth-child(4) .listInfo {
        margin-top: 220px;
    }

    footer span {
        margin: 0 0 10px 15px !important;
        padding: 0 !important;
        display: block;
        width: 100%;
        border: 0 none !important;
    }

    footer {
        height: 210px;
    }

    .downloadBox {
        bottom: 0;
        top: unset;
        height: 60px;
        overflow: hidden;
    }

    .downloadBox .w1200 .downloadButtons {
        width: auto;
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: 0;
        height: 60px;
        border: 0 none;
        border-top: 1px solid #ddd;
    }

    .downloadBox .w1200 .downloadButtons h2 {
        float: left;
        width: 10%;
        border: 0 none;
        border-radius: 0;
        padding: 0;
        height: 60px;
    }

    .downloadBox .w1200 .downloadButtons h2 img {
        margin-top: 15px;
    }

    .downloadBox .w1200 .downloadButtons h2 span {
        display: none;
    }

    .downloadBox .w1200 .downloadButtons button {
        float: left;
        width: 43%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
    }

    .downloadBox .w1200 .downloadButtons button:nth-child(2) {
        border-right: 1px solid #ddd;
        border-bottom: 0 none;
        padding-bottom: 0;
    }

    .downloadBox .w1200 .downloadButtons button img {
        margin-left: 10px;
    }

    .downloadBox .w1200 .downloadButtons button span {
        font-size: 20px;
        margin: 0 0 0 10px;
        display: inline-block;
        line-height: 1.2em;
        width: auto;
    }

    .section5 {
        background-image: none !important;
        margin: 100px 0 30px 0;
    }

    .section5 h2 {
        margin-bottom: 30px;
    }

    .section5 .bottomBox {
        width: 100%;
        height: 90px;
    }

    .section5 .bottomBox .bottomButton,
    .section5 .live_now_list li:nth-child(6),
    .section5 .live_now_list li:nth-child(7),
    .section5 .live_now_list li:nth-child(8),
    .blogBox button:nth-child(9) {
        display: none !important;
    }

    .section5 .live_now_button {
        width: 100%;
        padding-top: 10px;
        clear: both;
    }

    .section5 .live_now_button button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        width: 100%;
        height: 60px;
        line-height: 60px;
        font-size: 15px;
    }

    .section5 .live_now_button button img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }

    .section5 .live_now_button button span {
        display: inline-block;
        width: auto;
        font-size: 20px;
        line-height: 24px;
        margin: 0;
    }

    .section5 .w1200 .list_box {
        position: relative;
        padding: 0 15px;
    }

    .section5 .live_now_list {
        width: 100%;
    }

    .section5 .live_now_list .live_now_item {
        float: none;
        margin: 0 0 10px 0;
        width: 100%;
        overflow: hidden;
    }

    .section5 .live_now_list .live_now_item .live_now_address {
        line-height: 40px;
    }

    .section5 .live_now_list .live_now_item .live_now_inbox .live_now_title .live_now_device {
        font-size: 14px;
    }

    .section5 .live_now_list .live_now_item .live_now_inbox .live_now_title .live_now_intitle {
        font-size: 14px;
        width: 100px;
    }

    .blogBox {
        margin: 350px 0 0 0;
        width: 100%;
    }

    .blogBox button {
        width: calc(48% - 15px);
    }

    .go_box {
        display: none;
    }

    footer .quickLink {
        top: -15px;
    }

    .App {
        overflow: hidden;
    }
}
