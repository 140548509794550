@charset "UTF-8";

@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

.modalBox {
    font-family: 'Pretendard-Regular', serif;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: #11111188;
    left: 0;
    top: 0;

    & button {
        background: transparent;
    }

    .modalOutBox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .modalBg {
        text-align: left;
        background: #fff;
        display: inline-block;
        width: 800px;
        height: 580px;
        border-radius: 15px;
    }

    .modalHeader {
        position: relative;
        display: block;
        height: 80px;
        line-height: 80px;
        margin: 0 20px;

        & h2 {
            float: left;
            color: #333;
            font-size: 20px;
            font-weight: bold;
        }

        .modalClose {
            position: absolute;
            right: 0;
            top: 30px;
        }

        .alertBox {
            margin-top: 12px;
            float: left;
            width: 490px;
            padding: 8px 10px;
            margin-left: 20px;
            font-size: 14px;
            line-height: 1.3em;
            font-weight: bold;
            background: #F5F9FF;
            border: 1px solid #E3E8F1;
            border-radius: 10px;
        }

        .alertBox img {
            float: left;
        }

        .alertBox span {
            float: left;
            margin-left: 15px;
            letter-spacing: -1px;
        }
    }

    .modalBody {
        border-top: 1px solid #dadada;
        border-bottom: 1px solid #dadada;
        height: 440px;

        & dl, & dl dt, & dl dd {
            float: left;
            font-size: 14px;
            height: 60px;
            line-height: 60px;
        }

        & dl {
            margin: 0 20px;
            display: block;
            width: calc(100% - 40px);
            height: auto;
        }

        & dl:nth-child(1) {
            border-bottom: 1px solid #dadada;
        }

        & dl:nth-child(2) dt:nth-child(3),
        & dl:nth-child(2) dd:nth-child(4) {
            height: 90px;
        }

        & dl .w15 {
            width: 15%
        }

        & dl .w35 {
            width: 35%
        }

        & dl .w85 {
            width: 85%
        }

        & dl dt {
            font-weight: bold;
            color: #000920;
        }

        & dl dd {
            display: flex;
            align-items: center;
        }

        & dl dt span {
            padding-left: 10px;
        }

        & dl dt .important {
            padding: 0;
            color: #FF0000;
        }

        & dl:nth-child(1) dd {
            text-align: right;
        }

        & dl dd input,
        & dl dd select,
        & dl dd textarea {
            color: #111;
            border: 1px solid #dadada;
            padding: 0 10px;
            width: calc(100% - 30px);
            line-height: 40px;
            height: 40px;
            border-radius: 5px;
            margin: 0;
        }

        & dl dd textarea {
            padding: 10px;
            line-height: 1.4em;
            height: calc(80px - 20px);
        }

        & dl dd input::placeholder,
        & dl dd textarea::placeholder {
            color: #C7C8CE;
        }

        & dl dd select {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            height: 42px;
            background-image: url(../assets/image/icon_arrow_down.png);
            background-repeat: no-repeat;
            background-position: calc(100% - 5px) center;
        }

        & dl dd .deviceButton {
            line-height: 40px;
            height: 40px;
            padding: 0 20px;
            color: #fff;
            font-size: 14px;
            background: #255DF9;
            border-radius: 5px;
            font-weight: bold;
        }

        & dl dd .subTitle {
            color: #333;
        }

        & dl dd .subtitleBox {
            display: inline-block;
            padding: 0;
            margim: 0;
            line-height: 1.4em;
        }

        & dl dd.workBox .subtitleBox:nth-child(1) {
            width: 30%;
        }

        & dl dd.workBox .subtitleBox:nth-child(2) {
            width: 40%;

            > select {
                width: calc(50% - 10px);
                margin-right: 10px;
            }
        }

        & dl dd.workBox .subtitleBox:nth-child(2) select {
            width: 45%;
            margin-right: 10px;
        }

        & dl dd.workBox .subtitleBox:nth-child(3) {
            width: 30%;
        }

        & dl dd .subtitleBox .subTitle {
            display: inline-block;
            width: 100%;
        }

        & dl dd .subtitleBox .subInfo {
            display: inline-block;
            font-weight: bold;
            color: #666666;
            margin-left: 10px;
            line-height: 1.1em;
        }

        & dl dd .carendaButton {
            padding: 0 10px;
            background: url(../assets/image/icon_calenda.png) no-repeat calc(100% - 10px) center;
        }

        & dl dd.addressBox input {
            width: calc(85% - 40px);
            margin-right: 10px;
        }

        & dl dd.addressBox button {
            width: 15%;
            border: 1px solid #255DF9;
            background: #fff;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 16px;
            color: #255DF9;
            border-radius: 5px;
        }

        & dl dd.tabBox {
            display: flex;
            align-items: center;
        }

        & dl dd.tabBox button {
            width: 33%;
            height: 40px;
            line-height: 40px;
            display: flex;
            align-items: center;
            border: 1px solid #dadada;
            background: #F9F9F9;
        }

        & dl dd.tabBox button:nth-child(1) {
            border-radius: 5px 0 0 5px;
        }

        & dl dd.tabBox button:nth-child(2) {
            border-left: 0 none;
        }

        & dl dd.tabBox button:nth-child(3) {
            border-radius: 0 5px 5px 0;
            border-left: 0 none;
        }

        & dl dd.tabBox button img {
            margin: 0 10px 0 30px;
        }

        & dl dd.tabBox button span {
            font-weight: bold;
            font-size: 16px;
        }

        & dl dd.tabBox button.active {
            border: 2px solid #255DF9;
        }

        & dl dd.contentsBox {
            height: 100px;
        }

        .deviceSelectBox {
            width: 100%;
            line-height: 40px;
            height: 40px;
            background: #255df9;
            border-radius: 5px;
            position: relative;
            color: #fff;

            & span {
                margin-top: 10px;
                height: 20px;
                line-height: 20px;
                display: inline-block;
                float: left;
            }

            & span:nth-child(1) {
                font-size: 16px;
                font-weight: bold;
                padding: 0 20px;
            }

            & span:nth-child(2) {
                font-size: 14px;
                padding: 0 20px;
                border-left: 1px solid #fff;
            }

            & span:nth-child(3) {
                font-size: 14px;
                padding: 0 20px;
                border-left: 1px solid #fff;
                max-width: 350px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                word-break: break-all;
                margin-right: 50px;
            }

            & button {
                position: absolute;
                right: 5px;
                top: 7px;
            }
        }

        & .important {
            display: none;
        }
    }

    .modalBottom {
        display: flex;
        justify-content: flex-end;
        width: calc(100% - 50px);
        padding: 10px 25px;

        & button.sendButton {
            float: right;
            display: flex;
            align-items: center;
            line-height: 40px;
            height: 40px;
            padding: 0 20px;
            color: #fff;
            font-size: 14px;
            background: #255DF9;
            border-radius: 5px;
            font-weight: bold;
        }

        & button.sendButton img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }
    }

    @media (max-width: 512px) {
        body {
            font-size: 16px;
        }

        .modalOutBox {
            position: fixed;
            display: block;
        }

        .modalBg {
            border-radius: 0;
            width: 100%;
            height: 100%;
        }

        .modalHeader {
            margin: 0;
            height: 50px;
            line-height: 50px;

            .modalClose {
                top: 15px;
                right: 15px;
            }

            .alertBox {
                margin: 0;
                border-radius: 0;
                width: 100%;
                font-size: 11px;

                & img {
                    width: 35px;
                    height: 30px;
                }
            }

            & h2 {
                margin-left: 15px;
                font-size: 18px;
            }
        }

        .modalBody {
            padding-bottom: 80px;
            border: 0 none;

            & dl {
                margin: 0 !important;
            }

            & dl,
            & dl dt,
            & dl dd {
                font-size: 12px !important;
                width: calc(100% - 15px) !important;
                padding: 0 10px !important;
                height: auto;
                line-height: 35px;
            }

            & dl dt {
                margin-top: 10px;
                font-size: 15px !important;
                height: 30px;
                line-height: 30px;
            }

            & dl dt span {
                width: 100% !important;
            }

            & dl {
                border: 0 none !important;
            }

            & .inputBox:nth-child(2) dt,
            & .inputBox:nth-child(2) dd {
                display: none;
            }

            & .inputBox:nth-child(2) dt:nth-child(9),
            & .inputBox:nth-child(2) dd:nth-child(10) {
                display: block;
            }

            & dl dd.contentsBox {
                height: 180px !important;
            }

            & dl dd.contentsBox textarea {
                height: 160px !important;
            }
            & dl dd.contentsBox textarea::placeholder {
                color: transparent !important;
            }

            & .important {
                display: block;
                padding: 10px 10px 0 20px;
                clear: both;
            }

            & .important span {
                float: left;
                display: inline-block;
                color: #255df9;
                font-size: 13px;
                line-height: 1.2em;
            }
            & .important span:nth-child(1) {
                width: 10px;
            }
            & .important span:nth-child(2) {
                width: calc(100% - 10px);
            }
        }

        .modalBottom {
            background: #fff;
            border-top: 1px solid #dadada;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }
}
