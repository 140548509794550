@charset "UTF-8";

@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

.deviceBox {
    font-family: 'Pretendard-Regular', serif;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: #11111188;
    left: 0;
    top: 0;

    & button {
        background: transparent;
    }

    .deviceOutBox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .deviceBg {
        text-align: left;
        background: #fff;
        display: inline-block;
        width: 740px;
        min-height: 500px;
        border-radius: 15px;
    }

    .deviceHeader {
        position: relative;
        display: flex;
        align-items: center;
        height: 60px;
        line-height: 60px;
        margin: 0 20px;
        & button {
            padding: 10px;
            display: inline-block;
            margin: 0;
        }
        & h2 {
            display: inline-block;
            color: #000920;
            margin: 0;
            padding: 0;
            font-size: 20px;
            font-weight: bold;
        }
    }

    .deviceBody {
        border-top: 1px solid #dadada;
        padding: 0 20px;
        border-bottom: 1px solid #dadada;
        min-height: 450px;

        .deviceCategory {
            padding: 15px 0 0 0;
        }

        .deviceCategory button {
            display: inline-block;
            background: #F9F9F9;
            border: 1px solid #e0e0e0;
            border-left: 0 none;
            line-height: 40px;
            height: 40px;
            text-align: center;
            width: calc(100% / 7);
        }

        .deviceCategory button:nth-child(1) {
            border-left: 1px solid #dadada;
            border-radius: 5px 0 0 5px;
        }

        .deviceCategory button:last-child {
            border-radius:  0 5px 5px 0;
        }

        .deviceCategory button span {
            font-size: 16px;
            color: #666;
            font-weight: bold;
        }

        .deviceCategory button.active {
            border-color: #255DF9;
            background: #255DF9;
        }
        .deviceCategory button.active span {
            color: #fff;
        }

        .deviceCategoryInfo h3 {
            font-weight: bold;
            font-size: 14px;
            color: #000920;
        }

        .deviceCategoryInfo h3 .important,
        .deviceCategoryInfo h3 .multi {
            color: #FF0000;
            font-size: 12px;
            margin-left: 5px;
        }

        .deviceCategoryInfo .underline {
            margin-top: 10px;
            padding-top: 10px;
            border-top: 1px solid #dadada;
        }

        .deviceCategoryInfo button {
            margin: 0 10px 5px 0;
            width: calc(20% - 10px);
            line-height: 27px;
            height: 30px;
            border: 2px solid #F2F5FF;
            background: #F2F5FF;
            text-align: center;
            border-radius: 5px;
        }

        .deviceCategoryInfo button.active {
            border: 2px solid #255df9;
        }

        .deviceCategoryInfo button span {
            color: #222;
            font-weight: bold;
            margin: 0;
            padding: 0;
        }

    }

    .deviceBottom {
        display: flex;
        justify-content: flex-end;
        width: calc(100% - 50px);
        padding: 10px 25px;

        & button {
            float: right;
            display: flex;
            align-items: center;
            line-height: 40px;
            height: 40px;
            padding: 0 20px;
            color: #fff;
            font-size: 14px;
            background: #255DF9;
            border-radius: 5px;
            font-weight: bold;
        }

        & button img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }
    }
}
